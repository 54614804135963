import { Button, Typography } from '@material-ui/core'
import { createPage, CreatePageRequestParams } from 'app/api'
import {
  getAllPharmacies, GetAllPharmaciesRequestParams, GetAllPharmaciesResponse,
} from 'app/api'
import { useLocalization } from 'components'
import { RootState } from 'app/session/store'
import { Pharmacy, User, Training } from 'app/entities/types'
import { Card, Grid, makeStyles } from '@material-ui/core'
import { toast, useRouter } from 'app/utils'
import { userRoleHasPermission } from 'app/entities/methods'
import { Page } from 'components'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardListing, ListRequestParameters, ListHeaderProps, ListRowProps, ListSorting, CardField, StatusTag, StatusTagType } from 'components'
import { Color, FontWeight } from 'theme/style'
import clsx from 'clsx'
import { TrainingStatusValue, UserPermissionValue } from 'app/values'
import { updatePersistedListParameters } from 'app/session/actions'
import { userCan, userIs } from 'app/entities/methods'
import { UserRoleValue } from 'app/values'

const useStyles = makeStyles((theme) => ({
  dashboard_card: {
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  dashboard_cardPrimary: {
    backgroundColor: Color.Primary,
    '& >*': {
      color: Color.White
    }
  },
  dashboard_cardSuccess: {
    backgroundColor: Color.Success,
    '& >*': {
      color: Color.White
    }
  },
  dashboard_cardWarning: {
    backgroundColor: Color.Warning,
    '& >*': {
      color: Color.White
    }
  },
  dashboard_cardError: {
    backgroundColor: Color.Error,
    '& >*': {
      color: Color.White
    }
  },
  dashboard_cardInfo: {
    backgroundColor: '#31b7f5',
    '& >*': {
      color: Color.White
    }
  },
  dashboard_cardAlt: {
    backgroundColor: '#b051de',
    '& >*': {
      color: Color.White
    }
  },
  dashboard_cardAlt2: {
    backgroundColor: '#ff7a45',
    '& >*': {
      color: Color.White
    }
  },
  dashboard_cardAlt3: {
    backgroundColor: '#E175D4',
    '& >*': {
      color: Color.White
    }
  },
  dashboard_name: {
    textAlign: 'center'
  },
  dashboard_value: {
    textAlign: 'center',
    fontSize: '80px',
    fontWeight: FontWeight.UltraLight
  },
}))

type DataProps = {
  pharmacies?: {
    total: number
    active: number
    completed: number
    assign: number
    contact: number
    train: number
    recontact: number
    declined: number
    warnings: number
  }
}

const Dashboard = () => {
  const { t } = useLocalization()
  const router = useRouter()
  const session = useSelector((state: RootState) => state.session)

  const [isLoading, setIsLoading] = useState(false)
  const [items, setItems] = useState<Pharmacy[] | null>(null)

  const [dashboardData, setDashboardData] = useState<DataProps>({})

  function fetchItems(request?: ListRequestParameters) {
    setIsLoading(true)

    const params = request

    const encode = (): GetAllPharmaciesRequestParams => {
      return {
        ...params,
      }
    }

    const decode = (data: GetAllPharmaciesResponse): Pharmacy[] => {
      let object: Pharmacy[] = []

      data.pharmacies.map((pharmacy, i) => {
        let shouldAdd = (userRoleHasPermission(session.user.role, UserPermissionValue.AdminFeatures) === true)
          || (userIs(session.user, UserRoleValue.ZentivaMaster) === true)
          || (userIs(session.user, UserRoleValue.Rep) === true)
          || (userIs(session.user, UserRoleValue.CallCenter) === true && pharmacy.isPixotestActivated === true && pharmacy.trainings?.assignee?.id === session.user.id)
          || (userHasPharmacy(session.user, pharmacy) === true)

        if (shouldAdd === true) {
          object.push({
            id: pharmacy.id,
            name: pharmacy.name,
            address: pharmacy.address,
            city: pharmacy.city,
            state: pharmacy.state,
            country: pharmacy.country,
            phone: pharmacy.phone,
            email: pharmacy.email,
            zip: pharmacy.zip,
            vat: pharmacy.vat,
            pec: pharmacy.pec,
            sdi: pharmacy.sdi,
            isPixotestActivated: pharmacy.isPixotestActivated,
            loanDocument: pharmacy.loanDocument,
            loanDocumentSent: pharmacy.loanDocumentSent,
            loanSent: pharmacy.loanSent,
            trainings: pharmacy.trainings
          })
        }
      })
      return object

      function userHasPharmacy(user: User, item: Pharmacy): boolean {
        if (item.trainings == null) return false
        return item.trainings.assignee?.id === user.id
      }
    }

    getAllPharmacies(encode(), {
      response(data) {
        const items = decode(data)
        setupDashboard(items)
        setItems(items)
      },
      error(error, message) {
        toast.error(message)
      }
    })
  }

  function setupDashboard(pharmaciesData: Pharmacy[]) {
    let total = pharmaciesData.length
    let active = 0
    let completed = 0
    let declined = 0
    let assign = 0
    let contact = 0
    let train = 0
    let recontact = 0
    let warnings = 0

    pharmaciesData.forEach((p) => {
      if (p.isPixotestActivated === true) {
        active++
        if (p.loanSent === true && p.loanDocumentSent === true && p.trainings?.assignee == null) assign++
        if (p.trainings?.trainingStatus?.keyword === TrainingStatusValue.Completed) completed++
        if (p.trainings?.trainingStatus?.keyword === TrainingStatusValue.Declined) declined++
        if (p.trainings?.trainingStatus?.keyword === TrainingStatusValue.Contact) contact++
        if (p.trainings?.trainingStatus?.keyword === TrainingStatusValue.Appointed) train++
        if (p.trainings?.trainingStatus?.keyword === TrainingStatusValue.Reschedule) recontact++
      }
    })

    setDashboardData({
      pharmacies: {
        total: total,
        active: active,
        completed: completed,
        declined: declined,
        assign: assign,
        contact: contact,
        train: train,
        recontact: recontact,
        warnings: warnings,
      }
    })
  }

  useEffect(() => {
    fetchItems()
  }, [])

  useEffect(() => {
    const initialized = items != null
    setIsLoading(!initialized)
  }, [items])



  //RENDER

  return (
    <Page title={'Dashboard'} isLoading={isLoading}>
      <Grid container spacing={3}>

        {(
          userIs(session.user, UserRoleValue.CallCenter)
          || userIs(session.user, UserRoleValue.Operator)
          || userIs(session.user, UserRoleValue.ZentivaMaster)
          || userIs(session.user, UserRoleValue.Rep)
          || userIs(session.user, UserRoleValue.APS)
          || userIs(session.user, UserRoleValue.WebsiteManager)
        ) === false &&
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard title={'Farmacie totali'} value={dashboardData.pharmacies?.total} />
          </Grid>
        }

        {(
          userIs(session.user, UserRoleValue.CallCenter)
          || userIs(session.user, UserRoleValue.Operator)
        ) === false &&
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard type={'info'} title={'Farmacie arruolate'} value={dashboardData.pharmacies?.active}
              pixotestStatus={true} />
          </Grid>
        }

        {userIs(session.user, UserRoleValue.Rep) === false &&
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard type={'success'} title={'Completate'} value={dashboardData.pharmacies?.completed}
              trainingStatusParam={TrainingStatusValue.Completed} pixotestStatus={true} />
          </Grid>
        }

        {(
          userIs(session.user, UserRoleValue.CallCenter)
          || userIs(session.user, UserRoleValue.Operator)
        ) === false &&
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard type={'warning'} title={'Da assegnare'} value={dashboardData.pharmacies?.assign}
              pixotestStatus={true} assigned={false} loanSentStatus={true} loanDocumentSentStatus={true} />
          </Grid>
        }

        {userIs(session.user, UserRoleValue.Rep) === false &&
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard type={'alt3'} title={'Da contattare'} value={dashboardData.pharmacies?.contact}
              trainingStatusParam={TrainingStatusValue.Contact} pixotestStatus={true} />
          </Grid>
        }

        {userIs(session.user, UserRoleValue.Rep) === false &&
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard type={'alt2'} title={'Da formare'} value={dashboardData.pharmacies?.train}
              trainingStatusParam={TrainingStatusValue.Appointed} pixotestStatus={true} />
          </Grid>
        }

        {userIs(session.user, UserRoleValue.Rep) === false &&
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard type={'alt'} title={'Da ricontattare'} value={dashboardData.pharmacies?.recontact}
              trainingStatusParam={TrainingStatusValue.Reschedule} pixotestStatus={true} />
          </Grid>
        }

        {userIs(session.user, UserRoleValue.Rep) === false &&
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard type={'error'} title={'Annullate'} value={dashboardData.pharmacies?.declined}
              trainingStatusParam={TrainingStatusValue.Declined} pixotestStatus={true} />
          </Grid>
        }

      </Grid>
    </Page>
  )
}

export default Dashboard



type DashboardCardProps = {
  type?: 'default' | 'primary' | 'warning' | 'success' | 'error' | 'info' | 'alt' | 'alt2' | 'alt3',
  title: string,
  value?: string | number
  trainingStatusParam?: TrainingStatusValue
  assigned?: boolean
  pixotestStatus?: boolean
  loanSentStatus?: boolean
  loanDocumentSentStatus?: boolean
}

const DashboardCard = ({ ...props }: DashboardCardProps) => {
  const classes = useStyles()
  const router = useRouter()
  const dispatch = useDispatch()
  const session = useSelector((state: RootState) => state.session)

  function typeClass(): string {
    if (props.type == null) return ''
    if (props.type === 'default') return ''
    if (props.type === 'primary') return classes.dashboard_cardPrimary
    if (props.type === 'success') return classes.dashboard_cardSuccess
    if (props.type === 'warning') return classes.dashboard_cardWarning
    if (props.type === 'error') return classes.dashboard_cardError
    if (props.type === 'info') return classes.dashboard_cardInfo
    if (props.type === 'alt') return classes.dashboard_cardAlt
    if (props.type === 'alt2') return classes.dashboard_cardAlt2
    if (props.type === 'alt3') return classes.dashboard_cardAlt3
    return ''
  }

  function goToPharmacies() {
    if (userCan(session.user, UserPermissionValue.PharmaciesDisplay) !== true) return

    let opt: any = {}
    if (props.trainingStatusParam != null) {
      opt.trainingStatus = props.trainingStatusParam
    }
    if (props.assigned != null) {
      opt.assigned = props.assigned
    }
    if (props.pixotestStatus != null) {
      opt.isPixotestActive = props.pixotestStatus
    }
    if (props.loanSentStatus != null) {
      opt.loanSent = props.loanSentStatus
    }
    if (props.loanDocumentSentStatus != null) {
      opt.loanDocumentSent = props.loanDocumentSentStatus
    }


    dispatch(updatePersistedListParameters({
      listId: 'pharmacies',
      filters: opt
    }))

    router.history.push('/app/pharmacies')
  }

  return (
    <Card className={clsx(classes.dashboard_card, typeClass())} onClick={goToPharmacies}>
      <Typography className={classes.dashboard_value} variant={'body1'}>{props.value ?? '–'}</Typography>
      <Typography className={classes.dashboard_name} variant={'h3'}>{props.title}</Typography>
    </Card>
  )
}